<template>
  <CommonPage class="page-stockists">
    <section class="content">
      <section class="heading">
        <div class="heading-inner">
          <h1>
            <span v-for="(title, index) in titles" :key="`title-${index}`">
              {{ title }}
            </span>
          </h1>
        </div>
      </section>
      <slot></slot>
    </section>
  </CommonPage>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
export default {
  components: { CommonPage },
  props: {
    page: Object,
    blocks: Array,
  },
  computed: {
    titles() {
      if (this.page.title.indexOf(' - ') > -1) {
        return this.page.title.split(' - ')
      }
      return [this.page.title]
    },
  },
  mounted() {
    this.$store.commit('app/setBackground', 'background2')
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-stockists {
  /* background: var(--color-background2); */

  .heading {
    padding-top: 120px;
    padding-bottom: 100px;
    padding-left: var(--inset);
    @include cx-xl();
    @include cx-uc();
    @include clearfix();
    .heading-inner {
      @include offset(1);
      @include span(9);
    }

    h1 {
      display: flex;
      flex-direction: column;
    }
  }

  @include respond-to(m) {
    .heading {
      padding-top: 80px;
      .heading-inner {
        margin-left: 0;
      }
    }
  }

  @include respond-to(xs) {
    .heading {
      .heading-inner {
        @include offset(1);
        @include span(22);
      }
    }
  }
}
</style>