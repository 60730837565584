<template>
  <div class="cart-items">
    <CartItem
      :item="item"
      v-for="(item, k) in cart.items"
      :key="getKey('cart_item', k)"
    />
  </div>
</template>

<script>
import Vuex from 'vuex'
import CartItem from './CartItem.vue'

export default {
  computed: Vuex.mapState({
    cart: (state) => state.cart.cart,
  }),
  components: {
    CartItem,
  },
}
</script>

<style lang="scss">
.cart-items {
  position: absolute;
  top: 45px;
  bottom: 50px;
  left: 0;
  right: 0;
  overflow-y: auto;
}
</style>