<template>
  <div class="home-landing-double">
    <HomeSectionLandingMedia
      class="home-landing-double__column home-landing-double__column--1"
      :image-src="image1Src"
      :video-src="video1Sources"
      :text="section.text_1"
      :link="section.link_1"
    />
    <HomeSectionLandingMedia
      class="home-landing-double__column home-landing-double__column--2"
      :image-src="image2Src"
      :video-src="video2Sources"
      :text="section.text_2"
      :link="section.link_2"
    />
  </div>
</template>

<script>
import HomeSectionLandingMedia from "./HomeSectionLandingMedia.vue";

export default {
  components: { HomeSectionLandingMedia },

  computed: {
    image1Src() {
      return this.section.image_1?.preview_image?.src || this.section.image_1 || ''
    },
    image2Src() {
      return this.section.image_2?.preview_image?.src || this.section.image_2 || ''
    },
    video1Sources() {
      return this.section.video_1?.sources || []
    },
    video2Sources() {
      return this.section.video_2?.sources || []
    },
  },

  props: {
    section: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.home-landing-double {
  margin-bottom: 20px;
  height: calc(var(--1vh) * 100);

  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;

  @include respond-to('m') {
    grid-template-columns: 1fr;

    &__column--2 {
      display: none;
    }
  }
}
</style>
