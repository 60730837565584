<template>
  <section class="product-main">
    <ProductBreadcrumbs class="product-main__breadcrumbs" :product="product" />
    <ProductSlideshow class="product-main__slideshow" :product="product" />

    <div class="product-main__heading">
      <h1 class="product-main__title">{{ product.title }}</h1>
      <ShopifyProductPrice
        v-if="variant"
        class="product-main__price"
        :variant="variant"
        :product="product"
      />
      <ProductPayments class="product-main__payments" :product="product" />
    </div>

    <ProductDetails class="product-main__details" :product="product" />
  </section>
</template>

<script>
import ProductSlideshow from './ProductSlideshow.vue'
import ProductBreadcrumbs from './ProductBreadcrumbs.vue'
import ShopifyProductPrice from '../shopify/ShopifyProductPrice.vue'
import ProductPayments from './ProductPayments.vue'
import ProductDetails from './ProductDetails.vue'
import ProductGallery from './ProductGallery.vue'

export default {
  components: {
    ProductSlideshow,
    ProductBreadcrumbs,
    ShopifyProductPrice,
    ProductPayments,
    ProductDetails,
    ProductGallery,
  },
  props: { product: Object },
  computed: {
    variant() {
      return this.$store.state.product.selectedVariant
    },
  },
}
</script>
<style lang="scss">
@import '~/styles/base';

.product-main {
  padding-top: calc(var(--header-height) + 8px);
  min-height: calc(var(--1vh) * 100);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__breadcrumbs {
    @include cx-m;
    height: 16px;
    margin-bottom: 16px;
  }

  &__heading {
    margin-top: 160px;
    margin-bottom: auto;
    padding-bottom: 1em;
    margin-left: columns(2, 0);
    margin-right: columns(1, 0);

    @include respond-to(m) {
      margin-top: 20px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__title,
  &__price {
    @include cx-xl;
    @include cx-uc;

    .shopify-product-price__message {
      display: none;
    }
  }

  &__details {
    @include respond-to(m) {
      margin-top: 20px;
    }
  }

  &__slideshow {
    @include respond-to('m+') {
      display: none;
    }
  }
}
</style>