<template>
  <div class="digital-locket">
    <div class="digital-locket-header">
      <div class="title-span">
        <h3>JS DIGITAL LOCKET</h3>
      </div>
      <p class="header-copy">
        Introducing a new series from the Jasmin Sparrow community, a curated
        list of things that have recently caught our eye, pricked our ear or
        kept our mind spinning.
      </p>
    </div>

    <div class="digital-lockets">
      <div
        v-for="(article, index) in blog.articles"
        :key="`article-${index}`"
        class="article"
      >
        <div class="article-inner">
          <div class="article-heading">
            <h3 class="article-title">
              <a class="article-link" :href="article.url">
                {{ article.title }}
              </a>
            </h3>
          </div>
          <div class="article-image">
            <figure class="article-image-figure">
              <a class="article-link" :href="article.url">
                <ShopifyImage :src="article.image" />
              </a>
            </figure>
          </div>
          <div class="article-text">
            <div class="article-caption">
              <div class="article-excerpt">
                <span>{{ article.excerpt }}</span>
              </div>
              <span class="article-link-text">
                <a class="article-link" :href="article.url">Read More</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
export default { components: { ShopifyImage }, props: { blog: Object } }
</script>