<template>
  <div class="cart-footer">
    <div class="cart-footer__inner">
      <span v-if="hasItems" class="cart-footer__total"
        >Total
        <ShopifyMoney :amount="cart.total_price / 100" />
      </span>
      <a v-if="hasItems" class="cart-footer__next" href="/checkout">Checkout</a>
      <a v-if="!hasItems" class="cart-footer__next" href="/">Back to Shop</a>
    </div>
  </div>
</template>

<script>
import ShopifyMoney from '../shopify/ShopifyMoney.vue'
export default {
  components: { ShopifyMoney },
  computed: {
    hasItems() {
      return this.$store.getters['cart/getItemCount'] > 0
    },
    cart() {
      return this.$store.state.cart.cart
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.cart-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--foreground);
  text-align: left;
  padding: 0 30px;
  height: 50px;

  &__inner {
    position: relative;
    @include cx-uc;
    @include cx-l;
    line-height: 49px;
  }

  &__next {
    float: right;
  }
}
</style>