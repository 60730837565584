<template>
  <div class="home-collection">
    <ProductCards
      class="home-collection__cards"
      :products="section.collection.products.slice(0, 3)"
      mode="home"
    />
  </div>
</template>

<script>
import ProductCards from '~/components/product/ProductCards.vue'

export default {
  components: {
    ProductCards,
  },
  props: {
    section: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';
.home-collection {
  /* @include wrapper; */
  @include respond-to('m+') {
  }
}
</style>