<template>
  <nav class="product-breadcrumbs">
    <a class="product-breadcrumbs__breadcrumb" href="/collections/all">All</a>
    <a
      class="product-breadcrumbs__breadcrumb"
      v-if="collection"
      :href="`/collections/${collection.handle}`"
      >{{ collection.title }}</a
    >
  </nav>
</template>

<script>
export default {
  props: {
    product: Object,
  },
  computed: {
    collection() {
      return this.product?.metafields?.collection
    },
  },
}
</script>

<style lang="scss">
.product-breadcrumbs {
  &__breadcrumb {
    + * {
      &:before {
        content: '/';
        display: inline-flex;
        margin-left: 2px;
        margin-right: 4px;
      }
    }
  }
}
</style>