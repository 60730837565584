<template>
  <div class="cart__item__quantity">
    <slot />

    <button class="cart__item__decrement" @click.prevent="decrement">
      {{ decrementSymbol }}
    </button>

    <div class="cart__item__quantity__count">
      {{ quantity }}
    </div>

    <button class="cart__item__quantity__increment" @click.prevent="increment">
      {{ incrementSymbol }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    incrementSymbol: {
      type: String,
      default: '+',
    },
    decrementSymbol: {
      type: String,
      default: '−',
    },
    item: {
      type: Object,
    },
  },
  computed: {
    quantity() {
      return this.item.quantity
    },
  },
  methods: {
    async increment() {
      await this.update(this.quantity + 1)
    },
    async decrement() {
      await this.update(this.quantity - 1 < 0 ? 0 : this.quantity - 1)
    },
    async update(quantity) {
      return await this.$store.dispatch('cart/update', {
        id: this.item.id,
        quantity,
      })
    },
  },
}
</script>
