<template>
  <div class="product-details">
    <div class="product-details__info">
      <h2 class="product-details__heading">Description</h2>
      <div
        class="product-details__description"
        v-html="product.description"
      ></div>
      <div class="product-details__shipping">
        <h2 class="product-details__heading">Shipping</h2>
        <div
          class="product-details__shipping-text"
          v-html="shopify.settings.product_shipping"
        ></div>
      </div>
    </div>
    <ProductPurchase
      v-if="variant"
      :variant="variant"
      :product="product"
      class="product-details__purchase"
    />
  </div>
</template>

<script>
import ProductPurchase from './ProductPurchase.vue'
export default {
  components: { ProductPurchase },
  props: {
    product: Object,
  },
  computed: {
    shopify() {
      return this.$store.state.shopify
    },
    variant() {
      return this.$store.state.product.selectedVariant
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.product-details {
  &__info {
    display: grid;
    grid-template-columns: columns(2) columns(4) columns(6);
    gap: var(--gutter);
    margin-bottom: 40px;

    @include respond-to(xl) {
      grid-template-columns: 70px 160px 1fr;
    }

    @include respond-to(m) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }

  &__description,
  &__shipping-text {
    > * {
      margin-bottom: 1em;
    }

    @include helper-link-underline(1);
  }
}
</style>