<template>
  <div class="form-location">
    <div class="form-location__inner">
      <div class="form-location__select">
        <select v-if="value" class="form-location__input" @change="onChange">
          <option
            v-for="(location, index) in locations"
            :key="`location-${index}`"
            :value="location.currency"
          >
            {{ location.name }}
          </option>
        </select>
        <SvgSelectArrow class="form-location__arrow" />
      </div>
    </div>
    <button class="form-location__button" @click="$emit('close')">Enter Site</button>
  </div>
</template>

<script>
import SvgArrow from '../svg/SvgArrow.vue'
import { LOCATIONS, fetchLocation } from '~/lib/locations'
import SvgSelectArrow from '../svg/SvgSelectArrow.vue'

export default {
  components: { SvgArrow, SvgSelectArrow },
  props: {
    listId: {
      type: String,
      default: 'YsxRre',
    },
  },
  data() {
    return {
      value: null,
      locations: LOCATIONS,
    }
  },
  async mounted() {
    const location = await fetchLocation()
    this.value = location.currency
    this.$emit('change', this.value)
  },
  methods: {
    onChange($event) {
      const value = $event.target.value
      // this.$store.commit('currency/set', value)
      this.$emit('change', value)
      // Submit
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.form-location {
  @include placeholder(var(--color-midgrey));
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__inner {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--foreground);
  }

  &__select {
    width: 100%;
    position: relative;
  }

  &__input {
    @include appearance-none;
    @include cx-m;
    @include sans;
    width: 100%;
    color: var(--color-midgrey);
    flex: 1;
    padding: 6px 0 8px;
  }

  &__arrow {
    width: 8px;
    position: absolute;
    right: 4px;
    bottom: 10px;
  }

  &__button {
    @include cx-m;
    @include helper-underline(1);
    margin-top: auto;
    margin-bottom: 8px;
    margin-right: auto;
  }

  &__message {
    color: var(--color-midgrey);
  }
}
</style>

