<template>
  <section v-if="relatedCollection" v-once class="article-related">
    <div class="article-related__heading">
      <h2 class="article-related__inner">
        <a :href="relatedCollection.url"> {{ article.title }}'s Selects </a>
      </h2>
    </div>
    <div class="article-related__collection">
      <div class="article-related__blocks">
        <block-product
          v-for="(product, k) in relatedCollection.products"
          :key="`related-${k}`"
          :product="product"
        ></block-product>
      </div>
    </div>
  </section>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: { ShopifyImage },
  props: { article: Object },
  computed: {
    relatedCollection() {
      return false
    },
  },
}
</script>

<style lang="scss">
.article-related {
}
</style>