<template>
  <div class="common-link-search" :class="{ 'common-link-search--active': active }">
    <button v-if="!active && !query" class="common-link-search__button a" @click="onFocus">
      Search
    </button>

    <form v-if="active || query" class="common-link-search__form" action="/search">
      <input type="hidden" name="type" value="product" />
      <input
        ref="input"
        type="search"
        name="q"
        placeholder="Search"
        :value="query"
        required
        class="common-link-search__input"
      />
      <button class="common-link-search__submit"><SvgIconArrow /></button>
    </form>
  </div>
</template>

<script>
import SvgIconArrow from '../svg/SvgIconArrow.vue'
export default {
  components: { SvgIconArrow },
  props: {
    link: Object,
  },
  data() {
    return {
      query: `${window._data.searchTerms || ''}`,
      active: !!window._data.searchTerms,
    }
  },
  methods: {
    onFocus() {
      this.active = true
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.common-link-search {
  position: relative;
  @include placeholder(var(--color-midgrey));

  &__form {
    display: flex;
    align-items: baseline;
  }

  &__input {
    width: 100%;
    @include sans;
    @include cx-s;
    /* border-bottom: 1px solid transparent; */
    vertical-align: baseline;
    /* margin-bottom: 3px; */
  }

  &__submit {
    position: absolute;
    right: 0;
    top: 3px;
    width: 10px;
    height: 10px;
  }

  svg {
    width: 10px;
  }
}
</style>
