<template>
  <div v-if="product.variants.length > 1" class="product-options">
    <InputSelect
      class="product-options__select"
      :options="options"
      :option="variant.id"
      @change="onSelect"
    >
      {{ variant.title }}
      <SvgSelectArrow />
    </InputSelect>
  </div>
</template>

<script>
import SvgSelectArrow from '../svg/SvgSelectArrow.vue'
import InputSelect from '../input/InputSelect.vue'
export default {
  components: { SvgSelectArrow, InputSelect },
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    variant() {
      return this.$store.state.product.selectedVariant
    },
    options() {
      return this.product.variants.map((variant) => ({
        name: variant.title,
        value: variant.id,
      }))
    },
  },
  methods: {
    onSelect($event) {
      const value = $event.target.value
      const variant = this.product.variants.find((v) => v.id == value)
      this.$store.commit('product/setVariant', variant)
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.product-options {
  &__select {
    @include cx-l;
    @include cx-uc;
    svg,
    svg * {
      stroke: var(--foreground);
    }
    .input-select {
      &__text {
        display: flex;
        align-items: baseline;
        gap: 10px;
        svg {
          margin-top: auto;
          display: inline-flex;
          margin-bottom: 9px;
        }
      }
    }
  }

  &__input {
    @include appearance-none;
    @include cx-uc;
  }
}
</style>