import Axios from 'axios';
import qs from 'qs';

export const NewsletterSubscribe = async function({listId, email, data}) {
  if(!listId) throw new Error("List ID Required")
  if(typeof(email) == 'undefined') throw new Error("Email Required");
  
  const result = await Axios.request({
    url: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
    method: 'POST',
    headers: {
      "Access-Control-Allow-Headers": "*",
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
    },
    data: qs.stringify({
      g: listId,
      email,
      ...data,
    })
  })

  if(result.errors) throw new Error(result.errors.join(', '));

  return result
}

// export const MobileSubscribe = async function({ phone, }) {
//   if(!phone) throw new Error("Email Required");
  
//   try {
//     const result = await Axios.request({
//       method: 'POST',
//       url: 'https://jasminsparrow-services.vercel.app/api/klaviyo/sms',
//       data: {
//         phone,
//       }
//     })

//     return result;
//   } catch(e) {
//     throw new Error(e);
//   }
// }