<template>
  <div class="cart">
    <div class="cart__inner">
      <CartItems v-if="hasItems" />
      <CartEmpty v-else />

      <CartFooter class="cart__controls" />
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'

import CartEmpty from '~/components/cart/CartEmpty.vue'
import CartItems from '~/components/cart/CartItems.vue'
import CartHeading from '~/components/cart/CartHeading.vue'
import CartFooter from '../cart/CartFooter.vue'

export default {
  computed: {
    ...Vuex.mapState({
      cart: (state) => state.cart.cart,
      overlays: (state) => state.overlays,
    }),
    isActive() {
      return this.overlays.current == 'cart' && this.cart
    },
    hasItems() {
      return this.$store.getters['cart/getItemCount'] > 0
    },
  },
  components: {
    CartEmpty,
    CartItems,
    CartHeading,
    CartFooter,
  },
}
</script>
