<template>
  <div class="product-payment">
    Or {{ count }} payments of
    <ShopifyMoney :amount="variant.price / 100 / count" /> with
    <slot>
      <button class="a" @click="$store.commit('overlay/open', 'instalments')">
        Shop Pay
      </button>
    </slot>
  </div>
</template>

<script>
import Vuex from 'vuex'
import ShopifyMoney from '../shopify/ShopifyMoney.vue'

export default {
  props: {
    product: Object,
    count: Number,
  },
  computed: {
    ...Vuex.mapState({
      variant: (state) => state.product.selectedVariant,
    }),
  },
  components: { ShopifyMoney },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.product-payment {
  @include helper-link-underline(1);

  button {
    vertical-align: top;
    appearance: none;
    font-weight: inherit;
    display: inline-flex;
    color: inherit;
    margin-top: 1px;
  }
}
</style>