<template>
  <div class="header-submenus">
    <!-- Shop -->
    <div v-if="submenu == 'shop'" class="header-submenus__submenu header-submenus__submenu--shop">
      <CommonLinks
        mode="column"
        class="header-submenus__menu header-submenus__menu"
        :links="shopify.menus['submenu-shop-main-v2'].links"
      />

      <CommonLinks
        mode="column"
        class="header-submenus__menu header-submenus__menu"
        :links="shopify.menus['submenu-shop-additional-v2'].links"
      />
    </div>

    <!-- Customer Care -->
    <div v-if="submenu == 'info'" class="header-submenus__submenu header-submenus__submenu--info">
      <CommonLinks
        mode="column"
        class="header-submenus__menu header-submenus__menu"
        :links="shopify.menus['submenu-info'].links"
      />
    </div>

    <!-- About -->
    <div v-if="submenu == 'about'" class="header-submenus__submenu header-submenus__submenu--about">
      <CommonLinks
        mode="column"
        class="header-submenus__menu header-submenus__menu"
        :links="shopify.menus['submenu-about'].links"
      />
    </div>

    <!-- About -->
    <!-- <div -->
    <!--   v-show="submenu == 'currency'" -->
    <!--   class="header-submenus__submenu header-submenus__submenu--currency" -->
    <!-- > -->
    <!--   <CommonLinks -->
    <!--     mode="column" -->
    <!--     class="header-submenus__menu header-submenus__menu" -->
    <!--     :links="shopify.menus['submenu-currency'].links" -->
    <!--   /> -->
    <!-- </div> -->
  </div>
</template>

<script>
import CommonLinks from '../common/CommonLinks.vue'
import TransitionHeightWrap from '../transition/TransitionHeightWrap.vue'
export default {
  components: { CommonLinks, TransitionHeightWrap },
  computed: {
    submenu() {
      return this.$store.state.submenu.current
    },
    shopify() {
      return this.$store.state.shopify
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.header-submenus {
  grid-column: 1 / -1;
  display: grid;

  &__submenu {
    @include wrapper;
    grid-area: 1 / -1 / 1 / -1;
    display: grid;
    grid-template-columns: 4fr 20fr;

    &--shop {
      grid-template-columns: 4fr 3fr 17fr;
    }

    &--currency {
      grid-template-columns: 21fr 3fr;
      > *:nth-child(1) {
        padding-left: 6px;
      }
    }

    > * {
      &:nth-child(1) {
        grid-column: 2 /3;
      }
      &:nth-child(2) {
        grid-column: 3 / 4;
      }
    }
  }
}
</style>

