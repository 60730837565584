<template>
  <OverlaySlide class="overlay-mmenu" name="mmenu" side="left">
    <template #title> Menu </template>
    <div class="overlay-mmenu__menus">
      <CommonLinks
        class="overlay-mmenu__menu overlay-mmenu__menu--main"
        :links="shopify.menus['mobile-main'].links"
      />
      <nav class="overlay-mmenu__menu overlay-mmenu__menu--controls">
        <CommonLink class="overlay-mmenu__search" :link="{ title: 'search', url: '#searchmobile' }" />

        <!-- <div class="overlay-mmenu__currencies"> -->
        <!--   <button -->
        <!--     class="overlay-mmenu__currency" -->
        <!--     @click.prevent="showCurrencies = !showCurrencies" -->
        <!--   > -->
        <!--     {{ currency }} -->
        <!--   </button> -->
        <!--   <nav v-if="showCurrencies" class="overlay-mmenu__setcurrencies"> -->
        <!--     <CommonLink -->
        <!--       class="overlay-mmenu__setcurrency" -->
        <!--       :link="{ title: '$ NZD', url: '#setcurrency/NZD' }" -->
        <!--     /> -->
        <!--     <CommonLink -->
        <!--       class="overlay-mmenu__setcurrency" -->
        <!--       :link="{ title: '$ AUD', url: '#setcurrency/AUD' }" -->
        <!--     /> -->
        <!--     <CommonLink -->
        <!--       class="overlay-mmenu__setcurrency" -->
        <!--       :link="{ title: '$ USD', url: '#setcurrency/USD' }" -->
        <!--     /> -->
        <!--     <CommonLink -->
        <!--       class="overlay-mmenu__setcurrency" -->
        <!--       :link="{ title: '$ EUR', url: '#setcurrency/EUR' }" -->
        <!--     /> -->
        <!--     <CommonLink -->
        <!--       class="overlay-mmenu__setcurrency" -->
        <!--       :link="{ title: '₤ GBP', url: '#setcurrency/GBP' }" -->
        <!--     /> -->
        <!--   </nav> -->
        <!-- </div> -->
      </nav>
    </div>
  </OverlaySlide>
</template>

<script>
import CommonLinks from '../common/CommonLinks.vue'
import OverlaySlide from './OverlaySlide.vue'
import CommonLink from '../common/CommonLink.vue'
export default {
  components: { CommonLinks, OverlaySlide, CommonLink },
  data() {
    return {
      showCurrencies: false,
    }
  },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
    shopify() {
      return this.$store.state.shopify
    },
    currency() {
      return this.$store.state.currency.current
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.overlay-mmenu {
  &__menus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__menu {
    @include cx-m;
    display: flex;
    flex-direction: column;

    &--main {
      margin-top: 24px;
      margin-bottom: 24px;
      padding-left: var(--inset);
      padding-right: var(--inset);
      overflow-y: auto;
      flex-shrink: 1;

      > .common-link__wrapper {
        margin-bottom: 20px;
        > :not(.common-links) {
          @include cx-uc;
        }
        .common-link__links {
          margin-left: 24px;
          display: flex;
          flex-direction: column;
        }
      }
    }

    &--controls {
      margin-top: auto;
      margin-bottom: 12px;
    }
  }

  &__search {
    padding: 12px var(--inset);
    border-top: 1px solid var(--foreground);
    border-bottom: 1px solid var(--foreground);
  }

  &__currencies {
    padding: 12px var(--inset);
    display: flex;
    justify-content: space-between;
  }

  &__setcurrencies {
    display: flex;
    gap: 10px;
  }
}
</style>

