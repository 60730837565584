<template>
  <div class="overlay-location" :class="active ? 'overlay-location--active' : ''">
    <div class="overlay-location__inner">
      <div class="overlay-location__heading">
        <h3>CURRENCY</h3>
        <button class="overlay-location__close" @click="close">Close</button>
      </div>

      <div class="overlay-location__description">We see you are joining us from</div>

      <FormLocation class="overlay-location__form" @change="onChange" @close="close" />
    </div>
    <button class="overlay-location__background" @click="close"></button>
  </div>
</template>

<script>
import FormLocation from '../form/FormLocation.vue'

export default {
  components: {
    FormLocation,
  },
  data() {
    return {
      active: false,
      currency: null,
    }
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    description() {
      return this.settings.overlay_location_description
    },
  },
  mounted() {
    const currency = localStorage.getItem('currency')
    if (!currency) this.active = true
  },
  methods: {
    close() {
      localStorage.setItem('currency', this.currency)
      this.active = false
    },
    onChange(currency) {
      this.currency = currency
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.overlay-location {
  transition: all 0.4s ease;
  pointer-events: none;

  position: fixed;
  z-index: 10000000010;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  opacity: 0;

  &__inner {
    margin: auto;
    background: var(--background);
    border: 1px solid var(--foreground);
    padding: 14px 16px;
    max-width: 500px;
    width: calc(100vw - 48px);
    z-index: 10;
  }

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__heading {
    @include sans;
    @include cx-m;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__close {
    @include helper-underline(1);
  }

  &__description {
    @include cx-xl;
    @include cx-uc;
    max-width: 360px;
    margin-bottom: 80px;

    @include respond-to('m') {
      margin-bottom: 40px;
    }
  }

  &__form {
    max-width: 300px;
  }

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    background: var(--color-overlay-close);
  }
}
</style>
