<template>
  <ObserveResize
    class="header-mobile"
    @resize="(event) => event.height > 0 && $emit('resize', event)"
  >
    <div class="header-mobile__inner">
      <button
        class="header-mobile__hamburger"
        @click="$store.commit('overlay/set', 'mmenu')"
      >
        <SvgHamburger />
      </button>

      <a href="/" class="header-mobile__home">
        <SvgLogo class="header-mobile__logo" />
      </a>

      <button
        class="header-mobile__cart"
        @click="$store.commit('overlay/set', 'cart')"
      >
        <IconCart />
      </button>
    </div>
  </ObserveResize>
</template>

<script>
import SvgLogo from '../svg/SvgLogo.vue'
import SvgHamburger from '../svg/SvgHamburger.vue'
import SvgCart from '../svg/SvgCart.vue'
import IconCart from '../icon/IconCart.vue'
import ObserveResize from '../observe/ObserveResize.vue'
export default {
  components: { SvgLogo, SvgHamburger, SvgCart, IconCart, ObserveResize },
}
</script>

<style lang="scss">
@import '~/styles/base';

.header-mobile {
  &__inner {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, max-content) 1fr;
    padding: 18px var(--inset);
    gap: 10px;
    background: var(--background);
  }

  &__logo {
    width: 165px;
    display: block;
  }

  &__cart {
    display: flex;
    justify-content: flex-end;
    margin-top: -4px;
  }
}
</style>