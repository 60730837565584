<template>
  <div class="page-index">
    <HomeSections :sections="sections" />
  </div>
</template>

<script>
import HomeSections from '~/components/home/HomeSections.vue'

export default {
  components: {
    HomeSections,
  },
  props: {
    sections: {
      type: Array,
    },
  },
}
</script>

<style lang="scss">
.page-index {
  /* padding-top: var(--header-height); */
}
</style>
