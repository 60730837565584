<template>
  <div class="blog-journal">
    <JournalLanding class="blog-journal__landing" :article="landingArticle" />
    <ArticleCards class="blog-journal__cards" :articles="blog.articles" />
  </div>
</template>

<script>
import JournalLanding from '../journal/JournalLanding.vue'
import ArticleCards from '../article/ArticleCards.vue'

export default {
  components: { JournalLanding, ArticleCards },
  props: {
    blog: Object,
  },
  computed: {
    landingArticle() {
      return this.blog.articles[0]
    },
  },
}
</script>

<style lang="scss">
.blog-journal {
  &__landing {
    margin-bottom: 40px;
  }
}
</style>