<template>
  <div class="page-collection">
    <div class="page-collection__collections">
      <CommonLinks
        mode="column"
        class="page-collection__collection page-collection__collection--main"
        :links="menus['submenu-shop-main-v2'].links"
      />

      <CommonLinks
        mode="column"
        class="
          page-collection__collection page-collection__collection--additional
        "
        :links="menus['submenu-shop-additional-v2'].links"
      />
    </div>

    <ProductCards class="page-collection__cards" :products="products" />

    <ObserveIntersection
      v-if="loaded"
      class="page-collection__infinite"
      @intersect="onIntersection"
    />
  </div>
</template>


<script>
import ProductCards from '../product/ProductCards.vue'
import CommonLinks from '../common/CommonLinks.vue'
import ObserveIntersection from '../observe/ObserveIntersection.vue'
import { collection as CollectionApi } from '../../lib/shopify'
export default {
  components: { ProductCards, CommonLinks, ObserveIntersection },
  props: {
    collection: Object,
  },
  data() {
    return {
      products: [],
      page: 1,
      loaded: false,
      hasNextPage: true,
    }
  },
  computed: {
    menus() {
      return this.$store.state.shopify.menus
    },
  },
  mounted() {
    this.products = this.collection.products
    setTimeout(() => (this.loaded = true), 1000)
  },
  methods: {
    async onIntersection(v) {
      if (!this.hasNextPage) return
      await this.loadNextPage()
    },
    async loadNextPage() {
      this.loaded = false

      // Get next page
      const collection = await CollectionApi.getPage({
        handle: this.collection.handle,
        page: this.page + 1,
      })

      if (collection && collection.products.length) {
        this.products = [...this.products, ...collection.products]
        this.page = this.page + 1
      } else {
        this.hasNextPage = false
      }
      this.loaded = true
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-collection {
  padding-top: calc(var(--header-height));
  padding-bottom: 80px;

  &__collections {
    @include wrapper;
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 14fr;

    @include respond-to('m+') {
      min-height: 200px;
    }

    @include respond-to('m') {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include respond-to('m') {
    &__collection {
      &--main {
        padding-left: 20px;
      }
    }
  }

  @include respond-to('m+') {
    &__cards {
      @include wrapper;
    }
    &__collection {
      &--main {
        grid-column: 2 / 3;
      }
      &--additional {
        grid-column: 3 / 4;
      }
    }
  }
}
</style>