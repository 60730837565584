<template>
  <div class="product-related">
    <div class="product-related__heading">More In</div>
    <ProductCards
      class="product-related__cards"
      mode="related"
      :products="products"
    />
  </div>
</template>

<script>
import ProductCards from './ProductCards.vue'
export default {
  components: { ProductCards },
  props: {
    products: Array,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';
.product-related {
  padding-top: 40px;

  &__heading {
    @include cx-m;
    @include cx-uc;
    text-align: center;
    padding-bottom: 40px;
  }
}
</style>