<template>
  <nav class="article-pagination">
    <div class="article-pagination__back">
      <a href="/blogs/journal">Back to Journal</a>
    </div>
    <div class="article-pagination__posts">
      <a
        v-if="blog.previous_article"
        :href="blog.previous_article"
        class="article-pagination__prev"
        >Previous Post</a
      >
      <a
        v-if="blog.next_article"
        :href="blog.next_article"
        class="article-pagination__next"
        >Next Post</a
      >
    </div>
  </nav>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: { ShopifyImage },
  props: { blog: Object, article: Object },
}
</script>

<style lang="scss">
@import '~/styles/base';
.article-pagination {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px;
  border-top: 1px solid var(--color-dark);
  font-size: 24px;
  line-height: 1;
  text-transform: uppercase;
  &__prev,
  &__next {
    margin-left: 20px;
  }
  @include respond-to(m) {
    display: flex;
    flex-direction: column;
    &__prev,
    &__next {
      display: block;
      margin-left: 0;
      padding-top: 20px;
    }
  }
}
</style>