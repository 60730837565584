<template>
  <CommonPage class="page-search">
    <ProductCards class="page-search__cards" :products="products" />
  </CommonPage>
</template>


<script>
import ProductCards from '../product/ProductCards.vue'
import CommonPage from '../common/CommonPage.vue'

export default {
  components: { ProductCards, CommonPage },
  props: {
    products: Array,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-search {
  /* @include wrapper; */
  /* padding-top: calc(var(--header-height)); */

  &__cards {
    padding-top: 80px;
  }
}
</style>