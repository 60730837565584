<template>
  <div class="input-select">
    <select
      v-model="selected"
      class="input-select__select"
      @change.prevent="(e) => $emit('change', e)"
    >
      <option
        v-for="(_option, index) in options"
        :key="`inputSelect--${index}`"
        class="input-select__option"
        :value="_option.value"
      >
        {{ _option.name }}
      </option>
    </select>
    <div class="input-select__text">
      <slot>
        {{ option.name }}
      </slot>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    option: [Number, String],
    options: Array,
  },
  data() {
    return {
      selected: this.option,
    }
  },
}
</script>

<style lang="scss">
.input-select {
  position: relative;
  width: 100%;
  &__select {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 2;
    background: transparent;
    opacity: 0;
    cursor: pointer;
  }

  &__text {
    color: var(--foreground);
  }
}
</style>