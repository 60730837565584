<template>
  <div class="page-default">
    <div class="page-default__inner">
      <h1 class="page-default__heading">
        <span v-for="(title, index) in titles" :key="`title-${index}`">
          {{ title }}
        </span>
      </h1>

      <section
        class="page-default__description"
        v-html="page.content"
      ></section>
    </div>
    <PageImage class="page-default__image" :page="page" />
  </div>
</template>

<script>
import CommonPage from '../common/CommonPage.vue'
import ShopifyAccentuateImage from '../shopify/ShopifyAccentuateImage.vue'
import PageImage from './PageImage.vue'
export default {
  components: { CommonPage, ShopifyAccentuateImage, PageImage },
  props: {
    page: Object,
  },
  computed: {
    titles() {
      if (this.page.title.indexOf(' - ') > -1) {
        return this.page.title.split(' - ')
      }
      return [this.page.title]
    },
  },
  mounted() {
    this.$store.commit('app/setBackground', 'background2')
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-default {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex: 1;

  @include respond-to('xs') {
    grid-template-columns: 1fr;
  }

  &__inner {
    padding-left: var(--inset);
  }

  &__heading {
    @include cx-xl;
    @include cx-uc;
    padding: 240px 0 100px columns(1, 0);
    display: flex;
    flex-direction: column;

    @include respond-to(l) {
      padding-left: 0;
    }
  }

  &__description {
    @include cx-full;
    @include cx-m;
    padding-bottom: 80px;
    width: columns(6);
    margin-left: columns(4, 0);

    @include respond-to(l) {
      width: columns(10);
      margin-left: 0;
    }
    @include respond-to('xs') {
      width: columns(24);
    }
  }

  &__image {
    margin-left: calc(var(--gutter) * 0.5);
  }
}
</style>