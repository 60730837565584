<template>
  <form class="common-link--search-mobile" action="/search">
    <input type="hidden" name="type" value="product" />
    <input type="text" name="q" placeholder="Search" :value="query" required />
    <button><SvgIconArrow /></button>
  </form>
</template>

<script>
import SvgIconArrow from '../svg/SvgIconArrow.vue'
export default {
  components: { SvgIconArrow },
  props: {
    link: Object,
  },
  data() {
    return {
      query: `${window._data.searchTerms || ''}`,
    }
  },
}
</script>