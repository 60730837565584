<template>
  <div class="stockist-cards">
    <div
      v-for="(group, index) in getGroups"
      :key="getKey('stockist_group', index)"
      class="stockist-cards__group"
    >
      <div class="stockist-cards__heading">{{ group.heading }}</div>
      <StockistCard
        v-for="(stockist, _index) in group.stockists"
        :key="getKey('stockist', index, _index)"
        class="stockist-cards__card"
        :stockist="stockist"
      />
    </div>
  </div>
</template>

<script>
import StockistCard from './StockistCard.vue'

export default {
  components: { StockistCard },
  props: {
    filter: String,
    stockists: Array,
  },
  computed: {
    getHeading() {
      console.log('')
      return ''
    },
    getGroups() {
      const regions = this.stockists
        .filter((stockist) => stockist.country == this.filter)
        .reduce((c, stockist, k) => {
          const region = stockist.region
          if (c.indexOf(region) > -1) return c
          return [...c, region]
        }, [])

      return regions.map((region) => {
        return {
          heading: region,
          stockists: this.stockists.filter(
            (stockist) =>
              stockist.country == this.filter && stockist.region == region
          ),
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.stockist-cards {
  &__heading {
    @include cx-uc;
    margin-bottom: 20px;
  }

  &__card {
    white-space: pre-line;
    + .stockist-cards__heading {
      margin-top: 40px;
    }
    a:hover {
      @include helper-underline();
    }
  }
}
</style>