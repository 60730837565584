<template>
  <div
    class="overlay-newsletter"
    :class="active ? 'overlay-newsletter--active' : ''"
  >
    <div class="overlay-newsletter__heading">
      <h3>NEWSLETTER</h3>
      <button class="overlay-newsletter__close" @click="close">Close</button>
    </div>

    <div class="overlay-newsletter__description">
      {{ description }}
    </div>

    <FormNewsletter class="overlay-newsletter__form" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import FormNewsletter from '../form/FormNewsletter.vue'

export default {
  components: {
    FormNewsletter,
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    description() {
      return this.settings.overlay_newsletter_description
    },
  },
  mounted() {
    const hideTime = window.localStorage.getItem('newsletter_overlay') || false

    // Don't show if shown in last X time
    if (hideTime) {
      const currentTime = Date.now()
      const days = 1000 * 60 * 60 * 24
      const timeAgo = Math.round((currentTime - parseInt(hideTime)) / days)
      if (timeAgo < 30) return
    }

    setTimeout(() => {
      // Don't show if overlay already set
      if (window._data.logged_in) return

      //Finally, show
      this.active = true
    }, 3000)
  },
  methods: {
    close() {
      window.localStorage.setItem('newsletter_overlay', Date.now())
      this.active = false
    },
  },
}
</script>


<style lang="scss">
@import '~/styles/base.scss';

.overlay-newsletter {
  transition: all 0.4s ease;
  pointer-events: none;

  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 10000000000;

  background: var(--background);
  border: 1px solid var(--foreground);
  padding: 14px 16px;
  max-width: 500px;
  opacity: 0;

  width: calc(100vw - 48px);

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__heading {
    @include sans;
    @include cx-m;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__close {
    @include helper-underline(1);
  }

  &__description {
    @include cx-xl;
    @include cx-uc;
    max-width: 360px;
    margin-bottom: 80px;
  }

  &__form {
    max-width: 300px;
  }
}
</style>