<template>
  <div class="cart-heading" :class="getClass">
    <h2>Cart ({{ count }})</h2>
    <div class="close" @click.prevent="$store.dispatch('overlay/set', '')">
      Close
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    getClass() {
      if (this.count == 0) return 'cart-heading--empty'
      return ''
    },
    count() {
      return this.$store.getters['cart/getItemCount']
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.cart-heading {
  @include cx-s;
  @include cx-uc;
  text-align: left;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  border-bottom: 1px solid var(--dark);
  padding: 15px 20px 15px 40px;
  &__close {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }
}
</style>