<template>
  <OverlaySlide class="overlay-cart" name="cart" side="right">
    <template #title> Cart {{ count }}</template>
    <CommonCart class="overlay-cart__cart" />
  </OverlaySlide>
</template>


<script>
import CommonLinks from '../common/CommonLinks.vue'
import OverlaySlide from './OverlaySlide.vue'
import CommonCart from '../common/CommonCart.vue'
export default {
  components: { CommonLinks, OverlaySlide, CommonCart },
  computed: {
    overlay() {
      return this.$store.state.overlay.current
    },
    shopify() {
      return this.$store.state.shopify
    },
    count() {
      return this.$store.getters['cart/getItemCount']
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.overlay-cart {
  &__cart {
    padding-top: 60px;
  }
}
</style>