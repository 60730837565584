<template>
  <div class="moving-image">
    <video v-if="sources.length && visible" loop muted playsinline autoplay>
      <source v-for="source in sources" :src="source" :key="source" />
    </video>
  </div>
</template>

<script>
import resize from '../../mixins/resize'

export default {
  mixins: [resize],

  props: {
    mediaQuery: {
      type: String,
      default: ''
    },
    src: {
      type: [ Array, String ],
      default: ''
    }
  },

  data() {
    return {
      visible: true
    }
  },

  methods: {
    onResize(e) {
      if (
        this.mediaQuery &&
        window.matchMedia(`(${this.mediaQuery})`).matches
      ) {
        this.visible = false
      } else {
        this.visible = true
      }
    }
  },
  computed: {
    sources() {
      if(Array.isArray(this.src)) {
        return this.src.map((source) => source.url)
      }

      return [src]
    }
  }
}
</script>

<style lang="scss" scoped>
.moving-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

video {
  width: 100%;
  object-fit: cover;
}
</style>
