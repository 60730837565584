<template>
  <div class="digital-locket">
    <div class="digital-locket__header">
      <div class="digital-locket__title">
        <a href="/blogs/digital-locket">
          <h3>JS DIGITAL LOCKET</h3>
        </a>
        <h3>{{ article.title }}</h3>
      </div>
    </div>
    <section class="digital-locket__items" v-html="article.content"></section>
    <nav class="nav">
      <div class="back">
        <a href="/blogs/digital-locket">Back to Digital Locket</a>
      </div>
      <div class="posts">
        <a
          v-if="blog.previous_article"
          href="blog.previous_article"
          class="prev"
          >{{ blog.previous_article.title }}</a
        >
        <a v-if="blog.next_article" :href="blog.next_article" class="next"
          >Next</a
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    article: Object,
    blog: Object,
  },
}
</script>