<template>
  <div></div>
  <!-- <button -->
  <!--   class="common-link-currency a" -->
  <!--   :class="{ 'common-link--active': submenu == 'currency' }" -->
  <!--   @mouseover="$store.commit('submenu/set', 'currency')" -->
  <!-- > -->
  <!--   {{ display(currency) }} -->
  <!-- </button> -->
</template>

<script>
const currencies = ['NZD']

export default {
  props: {
    link: Object,
  },
  data() {
    return { currencies }
  },
  computed: {
    currency() {
      return this.$store.state.currency.current
    },
    submenu() {
      return this.$store.state.submenu.current
    },
    display() {
      return (code) => {
        let symbol = '$'
        if (code == 'EUR') symbol = '€'
        if (code == 'GBP') symbol = '₤'
        if (code == 'YEN') symbol = '¥'

        return `${symbol} ${code}`
      }
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.common-link--currency {
  &.common-link--active {
    @include helper-underline;
  }
  position: relative;

  nav {
    display: flex;
    padding-right: 10px;
    position: absolute;
    right: 100%;
    top: 0;
    white-space: nowrap;
    gap: 10px;
    button {
      color: var(--color-midgrey);
      cursor: pointer;
      &:hover {
        color: var(--color-dark);
      }
    }
  }
}
</style>

