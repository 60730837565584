<template>
  <section class="page-product">
    <ProductMain class="page-product__main" :product="product" />
    <ProductGallery class="page-product__gallery" :product="product" />
  </section>
</template>

<script>
import ProductGallery from '../product/ProductGallery.vue'
import ProductMain from '../product/ProductMain.vue'

export default {
  components: {
    ProductGallery,
    ProductMain,
  },
  props: { product: Object },
  mounted() {
    const variants = this.product.variants

    this.$store.commit('product/setProduct', this.product)
    this.$store.commit(
      'product/setVariant',
      variants.find((v) => v.available) || variants[0]
    )
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.page-product {
  @include wrapper;

  @include respond-to('m+') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 var(--gutter);

    &__main {
      position: sticky;
      top: 0px;
      margin-bottom: auto;
    }
  }

  @include respond-to('m') {
    &__gallery {
      display: none;
    }
  }
}
</style>