export const state = () => ({
  current: '',
});

export const actions = {
};

export const mutations = {
  set(state, value) {
    state.current = value;
  },
  toggle(state, value) {
    state.current = (state.current == value) ? '' : value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
