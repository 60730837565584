import {cart as ApiCart} from '../lib/shopify';

export const state = () => ({
  cart:  false,
  state: 'ready'
});

export const actions = {
  async load({ commit }, d) {
    var data = await ApiCart.get();
    commit('load', data);
  },
  async add({ commit, dispatch, state }, {id, quantity = 1}) {
    commit('setCartState', 'adding');

    await ApiCart.add({ 
      items: [
        {
          id, 
          quantity
        }
      ]
     });

    await dispatch('load');
    commit('setCartState', 'added');
    setTimeout(()=> {if(state.state == 'added') commit('setCartState', 'ready');}, 4000)
    return state.cart;
  },

  async remove({ commit, dispatch, state }, {id}) {
    console.log(id);
    commit('setCartState', 'removing');

    await ApiCart.update({
      updates: {
        [id]: 0
      }
    });

    await dispatch('load');
    commit('setCartState', 'removed');
    setTimeout(()=> {if(state.state == 'removed') commit('setCartState', 'ready');}, 4000)
  },
  async update({ commit, dispatch, state }, { id, quantity }) {
    commit('setCartState', 'updating');

    await ApiCart.update({
      updates: {
        [id]: quantity
      }
    });

    await dispatch('load');
    commit('setCartState', 'updated');
    setTimeout(()=> {if(state.state == 'updated') commit('setCartState', 'ready');}, 4000)
  },
}


export const mutations = {
  load(state, d) {
    state.cart = d;
  },
  setCartState(state, val) {
    state.state = val;
  }
}

export const getters = {
  getItemCount(state) {
    const cart = state.cart;
    if(!cart) return 0;
    return cart.item_count;
  },
  getCartState(state) {
    return state.state;
  },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
