<template>
  <div class="product-cards" :class="`product-cards--${mode}`">
    <ProductCard
      v-for="(product, k) in products"
      :key="'home_collection_' + k"
      class="product-cards__card"
      :product="product"
    />
  </div>
</template>

<script>
import ProductCard from '~/components/product/ProductCard.vue'

export default {
  components: {
    ProductCard,
  },
  props: {
    products: {
      type: Array,
    },
    mode: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.product-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  --column-count: 3;
  gap: var(--gutter);

  @include respond-to(m) {
    grid-template-columns: repeat(2, 1fr);
    --column-count: 2;
    gap: 20px 0;
  }

  @include respond-to(xxxs) {
    grid-template-columns: repeat(1, 1fr);
    --column-count: 1;
  }

  &--related {
    grid-template-columns: repeat(2, 1fr);
    --column-count: 2;
    gap: var(--gutter);
  }

  &--home {
    @include wrapper;

    @include respond-to('s+') {
      grid-template:
        ' a . . '
        ' . b c ';
      .product-cards__card {
        &:nth-child(1) {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
      }
    }
    @include respond-to('s') {
      grid-template-columns: repeat(3, 1fr);
      grid-template:
        ' a a . '
        ' . b b '
        ' . c c ';
      .product-cards__card {
        &:nth-child(1) {
          grid-area: a;
        }
        &:nth-child(2) {
          grid-area: b;
        }
        &:nth-child(3) {
          grid-area: c;
        }
      }
    }
  }
}
</style>
