
export const state = () => ({
  product: false,
  selectedVariant: false,
});

export const actions = {
};

export const mutations = {
  setProduct(state, product) {
    state.product = product;
  },
  setVariant(state, variant) {
    state.selectedVariant = variant;
  },
};

export const getters = {
  getOptionGroups(state) {
    const { product, selectedVariant } = state;

    // Get current active option by its group index
    const findActiveOption = (optionGroupIndex) => {
      const variant = product?.variants.find(
        (v) =>
          v.options[optionGroupIndex] ==
          selectedVariant?.options?.[optionGroupIndex]
      );
      
      return variant?.options[optionGroupIndex] || ''
    }

    // Find new variant by replacing an option
    const findNewVariant = (optionGroupIndex, option) => {
      return product.variants.find((variant) => {
        let options = [...variant.options]
        options[optionGroupIndex] = option;

        return JSON.stringify(options) == JSON.stringify([...variant.options]);
      })
    }

    // Format for use in selection component
    // TODO: Move into selection component
    const formatGroupOptions = (optionGroupIndex) => {
      const options = product?.variants.reduce((c, v, k) => {
        const option = v.options[optionGroupIndex];
        const hasOption = !!c.find((v) => v.title === option);
        
        if (!hasOption) {
          c = [
            ...c,
            {
              title: option,
              value: option,
              action: ($store) => {
                const variant = findNewVariant(optionGroupIndex, option);
                if(variant) $store.commit('product/setVariant', variant);
              },
              available: findNewVariant(optionGroupIndex, option)?.available,
            },
          ]
        }
        return c
      }, []) || [];

      return options
    }

    // Format options
    return state.product?.options?.map(
      (optionGroupName, optionGroupIndex) => {
        return {
          // Option group title
          title: `${optionGroupName} Select`,

          // Currently selected option group option
          active: findActiveOption(optionGroupIndex),

          // Option group option list
          options: formatGroupOptions(optionGroupIndex),
        }
      }
    ) || []
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
