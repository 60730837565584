<template>
  <div class="article-cards">
    <ArticleCard
      v-for="(article, idx) in articles"
      :key="getKey('ArticleCard', idx)"
      class="article-cards__card"
      :article="article"
    />
  </div>
</template>

<script>
import ArticleCard from './ArticleCard.vue'

export default {
  components: { ArticleCard },
  props: {
    articles: Array,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.article-cards {
  @include wrapper;
  display: flex;
  flex-direction: column;

  @include respond-to('m+') {
    margin-bottom: 160px;

    &__card {
      width: columns(8);

      &:nth-child(2n) {
        margin-left: auto;
        margin-right: 0;
        /* margin-top: -135px; */
        margin-top: -300px;
      }

      &:nth-child(2n + 1) {
        margin-left: columns(1, 0);
        margin-right: auto;
      }
    }
  }

  @include respond-to('m') {
    &__card {
      width: 100%;
      margin-bottom: 80px;
    }
  }
}
</style>