<template>
  <div class="journal-landing">
    <div class="journal-landing__content">
      <h2 class="journal-landing__title">
        {{ article.title }}
      </h2>
      <div class="journal-landing__details">
        <div class="journal-landing__date">
          {{ article.published }}
        </div>
        <div class="journal-landing__excerpt">
          <div v-html="article.excerpt"></div>
          <a class="journal-landing__link" :href="`/blogs/${article.handle}`"
            >Read More</a
          >
        </div>
      </div>
    </div>
    <div class="journal-landing__image-wrap">
      <ShopifyImage :src="article.image" class="journal-landing__image" />
    </div>
  </div>
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'
export default {
  components: { ShopifyImage },
  props: {
    article: Object,
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.journal-landing {
  @include respond-to('m+') {
    border-bottom: 1px solid var(--foreground);
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
  }

  &__content {
    margin-left: var(--inset);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 40px;
  }

  &__title {
    padding-top: calc(var(--header-height) + 180px);
    @include cx-uc;
    @include cx-xxl;
    margin-right: columns(3);

    @include respond-to('m') {
      padding-top: calc(var(--header-height) + 80px);
      padding-bottom: 120px;
    }
  }

  &__details {
    display: grid;
    @include respond-to('m+') {
      grid-template-columns: columns(2) columns(5) 1fr;
      gap: 0 var(--gutter);
    }
  }

  &__link {
    margin-top: 2em;
    @include helper-underline;
  }

  &__image-wrap {
    @include respond-to('m+') {
      height: calc(100vh - var(--header-height));
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>