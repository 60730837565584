export const LOCATIONS = [
  {
    name: 'New Zealand ($ NZD)',
    iso: 'NZ',
    currency: 'NZD',
  },
  {
    name: 'Australia ($ AUD)',
    iso: 'AU',
    currency: 'AUD',
  },
  {
    name: 'United States ($ USD)',
    iso: 'US',
    currency: 'USD',
  },
  {
    name: 'United Kingdom (₤ GBP)',
    iso: 'GB',
    currency: 'GBP',
  },
  {
    name: 'Canada ($ CAD)',
    iso: 'CA',
    currency: 'CAD',
  },
  {
    name: 'Europe (€ EURO)',
    iso: 'EU',
    currency: 'EUR',
  },
  {
    name: 'Rest of World ($ USD)',
    iso: 'ROW',
    currency: 'USD',
  },
]

export async function fetchLocation() {
  const response = await fetch('https://jasminsparrow.vercel.app/api/suggestions', {
    method: 'get',
  })
  const { country_code: countryCode } = await response.json()

  return LOCATIONS.find((location) => location.iso == countryCode) || LOCATIONS[0]
}
