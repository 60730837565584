<template>
  <ShopifyImage :src="slide.src" ratio="5x7" />
</template>

<script>
import ShopifyImage from '../shopify/ShopifyImage.vue'

export default {
  components: {
    ShopifyImage,
  },
  props: {
    slide: Object,
  },
}
</script>

<style lang="scss">
</style>