<template>
  <div class="cart-item">
    <div class="cart-item__image">
      <a :href="item.url">
        <ShopifyImage :src="item.image" ratio="6x8" />
      </a>
    </div>
    <div class="cart-item__inner">
      <div class="cart-item__title">
        <a :href="item.url">
          <span v-html="item.title"></span>
        </a>
      </div>
      <div class="cart-item__price">
        <ShopifyMoney :amount="item.price / 100" /> x
        {{ item.quantity }}
      </div>
      <div class="cart-item__controls">
        <button class="a" @click="$store.dispatch('cart/remove', { id: item.id })">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import CartItemQuantity from './CartItemQuantity.vue'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import ShopifyMoney from '../shopify/ShopifyMoney.vue'

export default {
  components: {
    CartItemQuantity,
    ShopifyImage,
    ShopifyMoney,
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    getTitle() {
      return this.item.product_title
    },
    getColor() {
      return this.item.properties.Color
    },
    getAmount() {
      return parseFloat(this.item.price)
    },
    getSubtotal() {
      return parseFloat(this.item.line_price)
    },
    getQuantity() {
      return this.item.quantity
    },
    getOptions() {
      let options = ''
      if (this.item.variant_title != '') options += `${this.item.variant_title}`
      // if(this.item.properties.Inscription) options += `<br>Inscription: ${this.item.properties.Inscription};`
      if (options == 'null') return ''
      return options
    },
  },
  methods: {
    remove() {
      this.$store.dispatch('cart/remove', { id: this.item.id })
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.cart-item {
  position: relative;
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 20px;
  padding: 10px 20px;

  + .cart-item {
    border-top: 1px solid var(--foreground);
  }

  &__inner {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 120px 50px;
    gap: 10px;

    @include respond-to(xs) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    @include cx-uc;
  }

  &__controls {
    @include helper-link-underline(true);
  }
}
</style>
