<template>
  <div class="home-section-landing-media">
    <img v-if="imageSrc" :src="imageSrc" class="home-section-landing-media__image" />
    
    <CommonVideo v-if="videoSrc.length" :src="videoSrc" class="home-section-landing-media__video" />

    <button v-if="!link" @click="onClick()" class="home-section-landing-media__button"></button>
    
    <a v-if="link" :href="link" class="home-section-landing-media__link">
      <span>
        <span class="home-section-landing-media__text">{{ text }}</span>
        <span class="home-section-landing-media__more"><span>View</span></span>
      </span>
    </a>
  </div>
</template>

<script>
import CommonVideo from "../common/CommonVideo.vue"
import { scrollToPosition } from "../../lib/scroll.js"

export default {
  components: {
    CommonVideo,
  },

  props: {
    videoSrc: [],
    imageSrc: String,
    text: String,
    link: String,
  },

  methods: {
    onClick() {
      scrollToPosition(window.innerHeight);
    }
  },
}

</script>


<style lang="scss">
@import '~/styles/base.scss';

.home-section-landing-media {
  height: 100%;
  position: relative;

  &:hover {
    .home-section-landing-media__more {
      opacity: 1;
    }
  }

  img,
  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__link {
    position: absolute;
    left:0;right:0;
    top:0;bottom:0;
    display: flex;
    z-index: 10;

    > span {
      margin: auto;
      color: var(--color-light);
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }


  }

  &__text {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &__more {
    opacity: 0;
    transition: all 0.2s ease;
    display: flex;
    justify-content: space-around;
    span {
      border-bottom: 1px solid var(--color-light);
    }
  }
}
</style>
