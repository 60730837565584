
export default {
  methods: {
    async preloadImage(src) {
      var img = new Image();
      img.src = src;
      return new Promise((res, rej) => {
        img.addEventListener('load', function (e) {
          res({ src, width: img.width, height: img.height });
        });
      })
    },
    async preloadImages(srcs) {
      var promises = srcs.map(async (src) => await this.preloadImage(src));
      return await Promise.all(promises);
    },
  }
}
