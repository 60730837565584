<template>
  <span class="common-link__wrapper" :class="getClass">
    <component :is="getComponent" :link="link" class="common-link" />
    <CommonLinks
      v-if="link.links && link.links.length"
      class="common-link__links"
      :links="link.links"
    />
  </span>
</template>

<script>
import kebabCase from 'just-kebab-case'
import CommonLinkCart from './CommonLinkCart.vue'
import CommonLinkDefault from './CommonLinkDefault.vue'
import CommonLinkCurrency from './CommonLinkCurrency.vue'
import CommonLinkBlank from './CommonLinkBlank.vue'
import CommonLinkSearch from './CommonLinkSearch.vue'
import CommonLinkSearchMobile from './CommonLinkSearchMobile.vue'
import CommonLinkSubmenu from './CommonLinkSubmenu.vue'
import CommonLinkSetcurrency from './CommonLinkSetcurrency.vue'

export default {
  name: 'CommonLink',
  components: {
    CommonLinkCart,
    CommonLinkDefault,
    CommonLinkCurrency,
    CommonLinkBlank,
    CommonLinkSearch,
    CommonLinkSearchMobile,
    CommonLinkSubmenu,
    CommonLinkSetcurrency,
  },
  props: {
    link: Object,
  },
  computed: {
    getComponent() {
      let component = 'CommonLinkDefault'
      if (this.link.url == '#cart') component = 'CommonLinkCart'
      // if (this.link.url == '#currency') component = 'CommonLinkCurrency'
      if (this.link.url == '#blank') component = 'CommonLinkBlank'
      if (this.link.url == '#search') component = 'CommonLinkSearch'
      if (this.link.url == '#searchmobile') component = 'CommonLinkSearchMobile'
      if (this.link.url.startsWith('#submenu/')) component = 'CommonLinkSubmenu'
      // if (this.link.url.startsWith('#setcurrency/'))
      //   component = 'CommonLinkSetcurrency'
      return component
    },
    getClass() {
      let classes = [`common-link--${kebabCase(this.getComponent)}`]
      if (this.link.active || this.link.child_active) classes = [...classes, 'common-link--active']
      return classes
    },
  },
}
</script>

<style lang="scss">
.common-link {
  &--active {
  }
}
</style>

