<template>
  <button class="common-link--set-currency a" :class="getClass" @click="onClick">
    {{ link.title }}
  </button>
</template>

<script>
export default {
  props: {
    link: Object,
  },
  computed: {
    currency() {
      return this.$store.state.currency.current
    },
    handle() {
      return this.link.url.split('/')[1]
    },
    getClass() {
      const isActive = this.currency.toUpperCase() == this.handle.toUpperCase()

      // console.log(this.currency, this.handle)

      return {
        'common-link--active': isActive,
      }
    },
  },
  methods: {
    onClick() {
      this.$store.commit('currency/set', this.handle)
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.common-link--currency {
  &.common-link--active {
    @include helper-underline;
  }
  position: relative;
}
</style>

